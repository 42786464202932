import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/aus-post-new-00006.jpg"

function AustraliaPost() {
  return (
    <>
      <Seo
        title="A vision, a lab & a new service | Craig Walker x Australia Post"
        image={thumb}
        description="Craig Walker worked with the Australia Post to develop, design and launch a new essential service aimed at reducing social isolation and increasing participation in communities."
      />

      <Splash
        text={
          <>
            A vision, a lab & <br />a new&nbsp;service
          </>
        }
        work={true}
      >
        <p>
          <b>
            Australia Post is the country’s largest retailer, with more than
            4,300 outlets and a workforce of some 35,000 around the country.
          </b>
        </p>
        <p>
          As part of its mission to help build connected, inclusive and
          prosperous communities, the government-owned business wanted to
          explore ways it could meet the growing challenge of social isolation
          across the country.
        </p>
        <p>
          Craig Walker worked with the Australia Post Social Design team to
          establish APCo-Lab, a community design lab in Melbourne, with the
          explicit objective of developing, designing and launching a new
          essential service aimed at reducing social isolation and increasing
          participation in communities.
        </p>
        <p>
          The one-year project delivered the design and pilot of Neighbourhood
          Welcome Service (NWS), which leveraged Australia Post’s mail redirect
          service to provide information, access and space to encourage
          participation in communities.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage>
          <Image imgName="aus-post-new-00006.jpg" />
        </WorkImage>

        <Numbers>
          <Number number={16} text="Community co-design sessions" />
          <Number number={45} text="Branded live pilot service components" />
          <Number number={1} text="New essential service" />
        </Numbers>

        <WorkImage>
          <Image imgName="aus-post-new-00002.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Co-designing in&nbsp;situ</h2>
          <p>
            Located in a shop front in the heart of Footscray, Melbourne,
            APCo-Lab was created as a space and resource for engaging with the
            local community to research, design and prototype ideas. By becoming
            part of the area’s social fabric, the team gained a deep
            understanding of the needs and ways of the local community.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="aus-post-new-00003.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Designing a&nbsp;pilot</h2>
          <p>
            Craig Walker and the team at APCo-Lab developed the service
            experience, service model, brand platform, marketing communications
            and collateral for NWS and piloted it with local organisations,
            businesses and communities in the local area of Maribyrnong.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="aus-post-new-00004.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Upskilling an&nbsp;organisation</h2>
          <p>
            Through APCo-Lab, we helped teams throughout Australia Post grow and
            hone their skills in the rapid conception, design, prototyping and
            iteration of multiple solutions with a community. The lab generated
            significant interest and support across Australia Post as an
            innovative and collaborative approach to new service design and
            delivery.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="aus-post-new-00005.jpg" />
        </WorkImage>

        <RelatedWork exclude="aus-post" />
      </ContentHolder>
    </>
  )
}

export default AustraliaPost
